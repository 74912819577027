import React from 'react';
import { Text, Box } from 'rebass';
import Layout from '../components/Layout';

const NotFoundPage = () => (
  <Layout logoHeader={true}>
    <Box width={[320, 400, 600]} my={2}>
      <Text color="red" fontSize={['9rem', '13rem', '16rem']}>
        404
      </Text>
      <Text color="darkRed" fontSize={['4rem', '5rem', '6rem']}>
        There isn't anything here
      </Text>
    </Box>
  </Layout>
);

export default NotFoundPage;
